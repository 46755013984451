/* The container for the entire element. */
.element {
  /* The Swirl container has a size defined using the component's props. */
  position: relative;
  /* Since the Swirl itself will overflow the container, the overflow will
  be hidden in order to make the element in smaller views. */
  overflow: hidden;
}

/* The phase details will show the currently highlighted Phase's name and
level description in the top right corner. This is based on where the ticker
arrow is pointing. */
.element > .phaseDetails {
  position: absolute;
  top: 0;
  right: 20px;
  /* The phase details are rendered above the swirl so it wont get blocked on
  specific screen sized. Pointer events are disabled so it won't interfere
  any interaction with the underlaying elements. */
  z-index: 2;
  pointer-events: none;
  color: var(--brand-text-dark);
}

/* The play button needs to be clickable for the modal to open up. */
.element > .phaseDetails > div > svg {
  pointer-events: auto;
}

.element > .phaseDetails > * {
  text-align: right;
}

/* The ticket's arrow will point to the outer most right point of the swirl. */
.element > .ticker {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 60%;
  aspect-ratio: 1;
  /* The ticket is rendered above the phase details so it wont get blocked on
  specific screen sized. Pointer events are disabled so it won't interfere
  any interaction with the underlaying elements. */
  z-index: 3;
  pointer-events: none;
}

.element > .ticker > .arrow {
  position: absolute;
  top: 33%;
  right: 0;
  margin-top: -35px;
  width: 34px;
  height: 68px;
  /* The image of the swirl's ticket arrow. */
  background-image: url(../../../assets/graphics/swirl-ticker-arrow.svg);
  background-size: 100% 100%;
}

/* The swirl container is responsible for keeping the container in aspect
in the bottom left corner of the element container. */
.element > .container {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  /* By setting the width to 100% while keeping the max height to 60% and
  keeping the apsect ratio to 1, the swirl's container is always kept inside of 
  the element container. */
  width: 100%;
  max-height: 60%;
  aspect-ratio: 1;
}

/* The swirl itself is positioned within the swirl container. */
.element > .container > .swirl {
  position: absolute;
  /* The swirl is positioned at an offset of a given number of percentages. 
  This will place the swirl partially off-screen. */
  top: -60%;
  left: -95%;
  /* The swirl will have an oversizes width and height based on the size of
  the swirl container. This will compensate for the off-screen position and
  will result in a screen filling swirl. */
  width: 190%;
  height: 190%;
  /* The background of the swirl. */
  background-size: 100% 100%;
}

/* The Segment is represents a line on a positioned from the center of the 
swirl, allow content to be placed somewhere on a swirl's segment. A rotation
transform can be applied to reposition it somewhere along the swirl. */
.element > .container > .swirl > .segment {
  position: absolute;
  /* The line is position in the center of the swirl reaching to an edge. */
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 0;
  transform-origin: center left;
}
